import { Card, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import parseISO from 'date-fns/parseISO'
import React, { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Notification } from '../../../hooks/useGetRecentNotifications/useGetRecentNotifications'
import useTimeLabel from '../../../hooks/useTimeLabel/useTimeLabel'
import NotificationItem from '../NotificationItem/NotificationItem'
import { ReactNullValue } from '../../../utils/nulls'

const messages = defineMessages({
    today: {
        defaultMessage: 'Today',
        description: 'Today'
    },
    yesterday: {
        defaultMessage: 'Yesterday',
        description: 'Yesterday'
    }
})

const sortNotificationsByTime = (
    notifications: Notification[]
): Notification[] => {
    return notifications.sort(
        (a, b) =>
            parseISO(b.targetTimestamp).getTime() -
            parseISO(a.targetTimestamp).getTime()
    )
}

const groupNotificationsByDay = (notifications: Notification[]) => {
    return notifications.reduce((groups, notification) => {
        if (!notification?.targetTimestamp) {
            // Handle the pseudo notification with a null targetTimestamp
            if (!groups['No Date']) {
                groups['No Date'] = []
            }
            groups['No Date'].push(notification)
            return groups
        }

        const date = format(
            parseISO(notification.targetTimestamp),
            'yyyy-MM-dd'
        )

        if (!groups[date]) {
            groups[date] = []
        }

        groups[date].push(notification)
        return groups
    }, {} as { [date: string]: Notification[] })
}

export interface NotificationListProps {
    notifications: Notification[]
    isLarge?: boolean
    onHandleClose: () => void
    onSetExpired: (notification: Notification) => void
    onSetOpenPermissionSnackbar?: (allowed: boolean) => void
}

const NotificationList: React.FC<NotificationListProps> = ({
    notifications,
    isLarge = false,
    onHandleClose,
    onSetExpired,
    onSetOpenPermissionSnackbar
}) => {
    const { palette, breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const getTimeLabel = useTimeLabel()
    const { formatMessage, locale } = useIntl()

    const groupedNotifications = groupNotificationsByDay(notifications)

    const renderDateHeader = useCallback(
        (date: string) => {
            const parsedDate = parseISO(date)
            let dateLabel: string

            if (isToday(parsedDate)) {
                dateLabel = formatMessage(messages.today)
            } else if (isYesterday(parsedDate)) {
                dateLabel = formatMessage(messages.yesterday)
            } else {
                const formatter = new Intl.DateTimeFormat(locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })
                dateLabel = formatter.format(parsedDate)
            }

            return (
                <CoreTypography
                    component="div"
                    variant={isLarge ? 'h5' : 'body2'}
                    color={isLarge ? 'text.primary' : 'text.secondary'}
                    px={isMobile ? 0 : 2}
                >
                    {dateLabel}
                </CoreTypography>
            )
        },
        [isLarge, locale, isMobile, formatMessage]
    )

    const renderNotification = (
        index: number,
        notification: Notification,
        showTime: boolean
    ) => {
        const bgSx = {
            bgcolor: !notification?.read ? palette.grey[100] : undefined
        }

        return (
            <Stack key={index} direction="column" gap={isMobile ? 2 : 1}>
                {showTime && (
                    <CoreTypography
                        component="div"
                        variant={isLarge ? 'h6' : 'body2'}
                        color={isLarge ? 'text.primary' : 'text.secondary'}
                        px={isMobile ? 0 : 2}
                    >
                        {getTimeLabel(notification.targetTimestamp)}
                    </CoreTypography>
                )}
                <Stack py={isMobile ? 0 : 1} px={isMobile ? 0 : 2} sx={bgSx}>
                    <Card
                        elevation={0}
                        variant={isLarge ? 'outlined' : undefined}
                        sx={bgSx}
                    >
                        <Stack p={isLarge ? 3 : 0}>
                            <NotificationItem
                                notification={notification}
                                handleClose={onHandleClose}
                                setExpired={onSetExpired}
                                setOpenPermissionSnackbar={
                                    onSetOpenPermissionSnackbar
                                }
                            />
                        </Stack>
                    </Card>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack gap={2} data-testid="notification-list">
            {/* Render Pseudo Notifications First */}
            {groupedNotifications['No Date'] &&
                groupedNotifications['No Date']
                    .filter((notification) => !notification.expired)
                    .map((notification, index) =>
                        renderNotification(index, notification, false)
                    )}

            {/* Render Date-Grouped Notifications */}
            {Object.keys(groupedNotifications)
                .filter((date) => date !== 'No Date') // Exclude 'No Date' from date logic
                .sort((a, b) => parseISO(b).getTime() - parseISO(a).getTime())
                .map((date) => {
                    const nonExpiredNotifications = groupedNotifications[
                        date
                    ].filter((notification) => !notification.expired)

                    if (nonExpiredNotifications.length === 0) {
                        return ReactNullValue
                    }

                    return (
                        <React.Fragment key={date}>
                            {renderDateHeader(date)}
                            {sortNotificationsByTime(
                                nonExpiredNotifications
                            ).map((notification, index, array) => {
                                const previousNotification =
                                    index > 0
                                        ? array[index - 1]
                                        : ReactNullValue
                                const showTime =
                                    !previousNotification ||
                                    notification.targetTimestamp !==
                                        previousNotification.targetTimestamp
                                return renderNotification(
                                    index,
                                    notification,
                                    showTime
                                )
                            })}
                        </React.Fragment>
                    )
                })}
        </Stack>
    )
}

export default NotificationList
