import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInYears from 'date-fns/differenceInYears'
import isToday from 'date-fns/isToday'
import parseISO from 'date-fns/parseISO'
import { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import useZonedDateTime from '../useZonedDateTime/useZonedDateTime'
import { utcToZonedTime } from 'date-fns-tz'

const messages = defineMessages({
    today: {
        defaultMessage: 'Today',
        description: 'Today'
    },
    yesterday: {
        defaultMessage: 'Yesterday',
        description: 'Yesterday'
    },
    justNow: {
        defaultMessage: 'Just now',
        description: 'Just now'
    },
    minutesAgo: {
        defaultMessage:
            '{count, plural, one {# minute ago} other {# minutes ago}}',
        description: 'Minutes ago'
    },
    hoursAgo: {
        defaultMessage: '{count, plural, one {# hour ago} other {# hours ago}}',
        description: 'Hours ago'
    },
    daysAgo: {
        defaultMessage: '{count, plural, one {# day ago} other {# days ago}}',
        description: 'Days ago'
    },
    weeksAgo: {
        defaultMessage: '{count, plural, one {# week ago} other {# weeks ago}}',
        description: 'Weeks ago'
    },
    monthsAgo: {
        defaultMessage:
            '{count, plural, one {# month ago} other {# months ago}}',
        description: 'Months ago'
    },
    yearsAgo: {
        defaultMessage: '{count, plural, one {# year ago} other {# years ago}}',
        description: 'Years ago'
    }
})

const useTimeLabel = () => {
    const { getZonedDate } = useZonedDateTime()
    const { formatMessage, locale } = useIntl()

    return useCallback(
        (timestamp: string) => {
            const currentDate = getZonedDate()
            const notificationDate = getZonedDate(parseISO(timestamp))

            if (isToday(notificationDate)) {
                const minutesDifference = differenceInMinutes(
                    currentDate,
                    notificationDate
                )
                const hoursDifference = differenceInHours(
                    currentDate,
                    notificationDate
                )
                const daysDifference = differenceInDays(
                    currentDate,
                    notificationDate
                )
                const weeksDifference = differenceInWeeks(
                    currentDate,
                    notificationDate
                )
                const monthsDifference = differenceInMonths(
                    currentDate,
                    notificationDate
                )
                const yearsDifference = differenceInYears(
                    currentDate,
                    notificationDate
                )

                if (minutesDifference < 10) {
                    return formatMessage(messages.justNow)
                } else if (minutesDifference < 60) {
                    return formatMessage(messages.minutesAgo, {
                        count: minutesDifference
                    })
                } else if (hoursDifference < 24) {
                    return formatMessage(messages.hoursAgo, {
                        count: hoursDifference
                    })
                } else if (daysDifference < 7) {
                    return formatMessage(messages.daysAgo, {
                        count: daysDifference
                    })
                } else if (weeksDifference < 4) {
                    return formatMessage(messages.weeksAgo, {
                        count: weeksDifference
                    })
                } else if (monthsDifference < 12) {
                    return formatMessage(messages.monthsAgo, {
                        count: monthsDifference
                    })
                } else {
                    return formatMessage(messages.yearsAgo, {
                        count: yearsDifference
                    })
                }
            } else {
                const formatter = new Intl.DateTimeFormat(locale, {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                })
                return formatter.format(notificationDate)
            }
        },
        [getZonedDate, formatMessage, locale]
    )
}

export default useTimeLabel
