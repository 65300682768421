import { useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { nudgeMessages } from '../../components/features/NotificationItem/NotificationItem'
import { NudgeType } from '../../graphql/generated/autogenerated'
import { ReactNullValue } from '../../utils/nulls'
import { ChallengeGoalReminder } from '../useGetRecentNotifications/useGetRecentNotifications'

const messages = defineMessages({
    thriveReset: {
        defaultMessage: 'Thrive Reset',
        description: `thrive reset notification title`
    },
    thriveChallenge: {
        defaultMessage: 'Thrive Challenge',
        description: `thrive challenge notification title`
    }
})

type BrowserNotificationState = 'default' | 'denied' | 'granted'

export enum BrowserNotificationPermission {
    DEFAULT = 'default',
    DENIED = 'denied',
    GRANTED = 'granted'
}

export enum BrowserNotificationType {
    RESET,
    CHALLENGE
}

const badgeIconUrl =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/447e218e-e5f5-4136-a26e-8918880a4d00/cms'

interface UseBrowserNotification {
    isPermissionGranted: boolean
    requestPermission: () => Promise<BrowserNotificationState>
    showNotification: (
        title: string,
        url: string,
        options: NotificationOptions
    ) => void
    generateBrowserNotification: (notification: any) => void
}

export const buildUrlForReset = (resetId: string) => {
    return `${window.location.origin}/reset/thrive/${resetId}`
}

export const buildUrlForChallenge = () => {
    return `${window.location.origin}/challenges`
}

export const checkBrowserNotificationPermission = () => {
    if (typeof Notification === 'undefined') {
        console.warn('This browser does not support desktop notification')
        return false
    }
    return true
}

export const useBrowserNotification = (): UseBrowserNotification => {
    const { formatMessage } = useIntl()
    const [permission, setPermission] = useState<BrowserNotificationState>(
        typeof Notification !== 'undefined'
            ? Notification.permission
            : BrowserNotificationPermission.DEFAULT
    )
    const isPermissionGranted = useMemo(() => {
        return permission === BrowserNotificationPermission.GRANTED
    }, [permission])

    const requestPermission =
        useCallback(async (): Promise<BrowserNotificationState> => {
            if (!checkBrowserNotificationPermission()) {
                return BrowserNotificationPermission.DENIED
            }
            try {
                const newPermission = await Notification.requestPermission()
                const browserNotificationState =
                    newPermission as BrowserNotificationState
                setPermission(browserNotificationState)
                return browserNotificationState
            } catch (error) {
                return BrowserNotificationPermission.DENIED
            }
        }, [])

    const generateBrowserNotification = useCallback(
        (nudge: any) => {
            switch (nudge?.nudgeType) {
                case NudgeType.SmartNudgeMsTeamsMvp:
                    return {
                        title: formatMessage(messages.thriveReset),
                        url: buildUrlForReset(nudge.nudgeMetadata?.resetId),
                        options: {
                            body: formatMessage(nudgeMessages.resetNudgeMessage)
                        } as Notification
                    }
                case ChallengeGoalReminder:
                    return {
                        title: formatMessage(messages.thriveChallenge),
                        url: buildUrlForChallenge(),
                        options: {
                            body: formatMessage(
                                nudgeMessages.challengeReminderNudgeMessage
                            )
                        } as Notification
                    }
                default:
                    return ReactNullValue
            }
        },
        [formatMessage]
    )

    const showNotification = useCallback(
        (title: string, url: string, options: NotificationOptions): void => {
            if (!checkBrowserNotificationPermission() || !isPermissionGranted) {
                return
            }

            options.icon = badgeIconUrl
            const notification = new Notification(title, options)

            notification.onclick = (event) => {
                event.preventDefault()
                window.location.href = url
            }
        },
        [isPermissionGranted]
    )

    useEffect(() => {
        if (checkBrowserNotificationPermission()) {
            setPermission(Notification.permission)
        }
    }, [])

    return {
        isPermissionGranted,
        requestPermission,
        showNotification,
        generateBrowserNotification
    }
}
