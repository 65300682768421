import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

interface ErrorBoundaryFallbackProps {
    error: Error
}
const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({
    error
}) => <div>Something went wrong: {error.message}</div>

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err: Error, info: React.ErrorInfo, props: any): ReactElement {
        return <ErrorBoundaryFallback error={err} />
    }
})

export const { bootstrap, mount, unmount } = lifecycles
export * from './shared'
