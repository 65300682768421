import { IconName } from '@fortawesome/fontawesome-common-types'
import { Box, Button, Stack } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { NudgeType } from '../../../graphql/generated/autogenerated'
import {
    BrowserNotificationPermission,
    useBrowserNotification
} from '../../../hooks/useBrowserNotification/useBrowserNotification'
import {
    BrowserNotificationPermissionType,
    ChallengeGoalReminder,
    Notification
} from '../../../hooks/useGetRecentNotifications/useGetRecentNotifications'
import { defaultResourceSelectedProps } from '../../../utils/defaultTrackingProps'

// We are using this override to match designs until we get a small version of the buttons.
export const buttonSxOverride = {
    maxWidth: '32px!important',
    minHeight: '32px!important',
    minWidth: '32px!important'
}

interface Messages {
    resetNudgeMessage: MessageDescriptor
    watchResetButton: MessageDescriptor
    challengeReminderNudgeMessage: MessageDescriptor
    viewChallengeButton: MessageDescriptor
    browserNotificationPermissionTitle: MessageDescriptor
}

export const nudgeMessages: Messages = {
    resetNudgeMessage: {
        id: 'resetNudgeMessage',
        defaultMessage:
            'Now would be a great time to reduce stress in 60 seconds with a Reset.',
        description: `reset nudge message`
    },
    watchResetButton: {
        id: 'watchResetButton',
        defaultMessage: 'Watch Reset',
        description: 'watch reset button'
    },
    challengeReminderNudgeMessage: {
        id: 'challengeReminderNudgeMessage',
        defaultMessage: 'Get ready, your challenge starts tomorrow!',
        description: 'challenge nudge message'
    },
    viewChallengeButton: {
        id: 'viewChallengeButton',
        defaultMessage: 'View Challenge',
        description: 'view challenge button'
    },
    browserNotificationPermissionTitle: {
        id: 'browserNotificationPermissionTitle',
        defaultMessage: 'Stay on top of your goals with browser notifications',
        description: 'browser notification permission title'
    }
}

export interface NotificationItemProps {
    notification: Notification
    handleClose: (notification?: Notification) => void
    setExpired: (notification: Notification) => void
    setOpenPermissionSnackbar?: (allowed: boolean) => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    handleClose,
    setExpired,
    setOpenPermissionSnackbar
}) => {
    const history = useHistory()
    const { palette } = useTheme()
    const { formatMessage } = useIntl()

    const { isPermissionGranted, requestPermission } = useBrowserNotification()
    const {
        notificationIcon,
        notificationMessage,
        notificationButtonTitle,
        notificationCtaRoute,
        notificationTrackingActivityType
    } = useMemo(() => {
        switch (notification.nudgeType) {
            case NudgeType.SmartNudgeMsTeamsMvp:
                return {
                    notificationIcon: 'face-relieved',
                    notificationMessage: nudgeMessages.resetNudgeMessage,
                    notificationButtonTitle: nudgeMessages.watchResetButton,
                    notificationCtaRoute: `/reset/thrive/${notification.nudgeMetadata?.resetId}`,
                    notificationTrackingActivityType:
                        'reset_notification_selected'
                }
            case ChallengeGoalReminder:
                return {
                    notificationIcon: 'flag',
                    notificationMessage:
                        nudgeMessages.challengeReminderNudgeMessage,
                    notificationButtonTitle: nudgeMessages.viewChallengeButton,
                    // This is hypothetical for now
                    notificationCtaRoute: `/challenges`,
                    notificationTrackingActivityType: 'challenge_reminder'
                }
            case BrowserNotificationPermissionType:
                return {
                    notificationIcon: 'bell',
                    notificationMessage:
                        nudgeMessages.browserNotificationPermissionTitle,
                    notificationButtonTitle: {
                        id: 'enableNotifications',
                        defaultMessage: 'Allow',
                        description: 'enable notifications button'
                    },
                    notificationCtaRoute: undefined,
                    notificationTrackingActivityType: 'notification_permission'
                }
            default:
                return {
                    notificationMessage: {
                        id: 'defaultMessage',
                        defaultMessage: '',
                        description: ''
                    },
                    notificationButtonTitle: {
                        id: 'defaultButtonTitle',
                        defaultMessage: '',
                        description: ''
                    },
                    notificationCtaRoute: '',
                    notificationTrackingActivityType: ''
                }
        }
    }, [notification])

    const dismissPermissionNotification = useCallback(
        (permission: boolean) => {
            const permissionTimeout = setTimeout(() => {
                setExpired(notification)
                setOpenPermissionSnackbar?.(permission)
            }, 1000)
            return () => clearTimeout(permissionTimeout)
        },
        [notification, setExpired, setOpenPermissionSnackbar]
    )

    const openPermission = useCallback(async () => {
        const permission = await requestPermission()
        switch (permission) {
            case BrowserNotificationPermission.DENIED:
                handleClose()
                dismissPermissionNotification(false)
                break
            case BrowserNotificationPermission.GRANTED:
                handleClose()
                dismissPermissionNotification(true)
                break
            default:
                break
        }
    }, [dismissPermissionNotification, handleClose, requestPermission])

    const onCtaClick = useCallback(() => {
        // This also needs to be swapped out when we have the tracking event.
        // It will be 'notificationTrackingActivityType' when we have the tracking event.
        // It cannot be swapped out now because AVO is type checking the activity type.
        Avo.resourceSelected({
            ...defaultResourceSelectedProps,
            featureType: 'notifications',
            activityType: 'reset_notification_selected'
        })

        if (notificationCtaRoute) {
            history.push(notificationCtaRoute)
            handleClose()
            const timeout = setTimeout(() => {
                setExpired(notification)
            }, 1000)
            return () => clearTimeout(timeout)
        } else {
            openPermission()
        }
    }, [
        history,
        setExpired,
        handleClose,
        notification,
        openPermission,
        notificationCtaRoute
    ])

    const onDismissClickHandler = useCallback(() => {
        Avo.resourceSelected({
            ...defaultResourceSelectedProps,
            featureType: 'notifications',
            activityType: 'reset_deferred'
        })
        handleClose()

        const timeout = setTimeout(() => {
            setExpired(notification)
        }, 1000)
        return () => clearTimeout(timeout)
    }, [notification, handleClose, setExpired])

    return (
        <Stack
            gap={1.25}
            direction="row"
            alignItems="start"
            data-testid="notification-item"
        >
            <IconButton
                variant="contained"
                color="secondary"
                sx={{
                    bgcolor: notification.read ? palette.grey[100] : undefined,
                    pointerEvents: 'none',
                    ...buttonSxOverride
                }}
            >
                <LeafIcon
                    icon={notificationIcon as IconName}
                    fontSize="small"
                    sx={{
                        color: palette.getContrastText(palette.grey[100])
                    }}
                />
            </IconButton>
            <Stack flex={1} gap={1}>
                <CoreTypography variant="body1">
                    {formatMessage(notificationMessage)}
                </CoreTypography>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Box>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={onCtaClick}
                            data-testid="notification-item-cta-button"
                        >
                            <CoreTypography>
                                {formatMessage(notificationButtonTitle)}
                            </CoreTypography>
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            size="small"
                            onClick={onDismissClickHandler}
                            data-testid="notification-item-dismiss-button"
                        >
                            <CoreTypography>
                                <FormattedMessage
                                    defaultMessage="Not Now"
                                    description="not now button"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default NotificationItem
