import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Popover,
    Stack,
    useMediaQuery
} from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import NotificationList from '../../../../components/features/NotificationList/NotificationList'
import NotificationsEmpty from '../../../../components/features/NotificationsEmpty/NotificationsEmpty'
import { Notification } from '../../../../hooks/useGetRecentNotifications/useGetRecentNotifications'
import { ROUTES } from '../../../../routes'
import { ReactNullValue } from '../../../../utils/nulls'

// We are using this override to match designs until we get a small version of the buttons.
export const buttonSxOverride = {
    maxWidth: '32px!important',
    minHeight: '32px!important',
    minWidth: '32px!important'
}

export type AchievementsCenterMenuProps = {
    isOpen: boolean
    notifications: Notification[]
    onHandleClose: () => void
    onSetExpired: (notification: Notification) => void
    onSetOpenPermissionSnackbar: (isOpen: boolean) => void
}

const messages = defineMessages({
    notifications: {
        defaultMessage: 'Notifications',
        description: 'title of the notifications modal'
    }
})

const NotificationMenu: React.FC<AchievementsCenterMenuProps> = ({
    isOpen,
    notifications,
    onHandleClose,
    onSetExpired,
    onSetOpenPermissionSnackbar
}) => {
    const history = useHistory()
    const { formatMessage } = useIntl()
    const { palette, breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    const toNotificationSettings = useCallback(() => {
        history.push(ROUTES.NOTIFICATION_SETTINGS)
        onHandleClose()
    }, [history, onHandleClose])

    const toNotificationFeed = useCallback(() => {
        history.push(ROUTES.NOTIFICATIONS)
        onHandleClose?.()
    }, [history, onHandleClose])

    const notificationsFeedEnabled = useIsFeatureEnabled(
        'NotificationsFeed',
        ReactNullValue,
        true
    )

    const expired = useMemo(() => {
        return (
            notifications?.every((notification) => notification.expired) ?? true
        )
    }, [notifications])

    return (
        <Stack data-testid="notification-menu" overflow="hidden">
            {!isMobile && (
                <Popover
                    open={isOpen}
                    onClose={onHandleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{
                        top: 75,
                        left: window.innerWidth - 150
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    PaperProps={{
                        style: {
                            borderWidth: 1,
                            display: 'flex',
                            position: 'relative',
                            flexDirection: 'column',
                            borderColor: palette.divider,
                            width: isMobile ? '100%' : 330
                        }
                    }}
                >
                    <Stack gap={0} height="100%">
                        <Stack
                            p={2}
                            top={0}
                            zIndex={1}
                            bgcolor="white"
                            direction="row"
                            position="sticky"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <CoreTypography customVariant="form">
                                {formatMessage(messages.notifications)}
                            </CoreTypography>
                            <IconButton
                                variant="contained"
                                color="secondary"
                                onClick={toNotificationSettings}
                                sx={buttonSxOverride}
                            >
                                <LeafIcon icon="gear" fontSize="small" />
                            </IconButton>
                        </Stack>
                        <Stack pt={0} flex={1} overflow="auto">
                            <Stack pb={3}>
                                {expired ? (
                                    <NotificationsEmpty />
                                ) : (
                                    <NotificationList
                                        notifications={notifications}
                                        onSetExpired={onSetExpired}
                                        onHandleClose={onHandleClose}
                                        onSetOpenPermissionSnackbar={
                                            onSetOpenPermissionSnackbar
                                        }
                                    />
                                )}
                            </Stack>
                            {notificationsFeedEnabled && (
                                <Box pt={3}>
                                    <Button onClick={toNotificationFeed}>
                                        <CoreTypography>
                                            <FormattedMessage
                                                defaultMessage="View all"
                                                description="view all button"
                                            />
                                        </CoreTypography>
                                    </Button>
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                </Popover>
            )}
            {isMobile && (
                <Dialog open={isOpen} fullScreen={true} onClose={onHandleClose}>
                    <DialogTitle>
                        <Stack
                            py={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <CoreTypography variant="h3">
                                {formatMessage(messages.notifications)}
                            </CoreTypography>
                            <Stack
                                gap={1.5}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <IconButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={toNotificationSettings}
                                >
                                    <LeafIcon icon="gear" />
                                </IconButton>
                                <IconButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={onHandleClose}
                                >
                                    <LeafIcon icon="close" />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <Stack overflow="visible" gap={2} height="100%">
                            <Stack pb={3} height="100%">
                                {expired ? (
                                    <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <NotificationsEmpty />
                                    </Stack>
                                ) : (
                                    <NotificationList
                                        notifications={notifications}
                                        onSetExpired={onSetExpired}
                                        onHandleClose={onHandleClose}
                                        onSetOpenPermissionSnackbar={
                                            onSetOpenPermissionSnackbar
                                        }
                                    />
                                )}
                            </Stack>
                            {notificationsFeedEnabled && (
                                <Box>
                                    <Button onClick={toNotificationFeed}>
                                        <CoreTypography>
                                            <FormattedMessage
                                                defaultMessage="View all"
                                                description="view all button"
                                            />
                                        </CoreTypography>
                                    </Button>
                                </Box>
                            )}
                        </Stack>
                    </DialogContent>
                </Dialog>
            )}
        </Stack>
    )
}

export default NotificationMenu
