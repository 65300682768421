import { defaultTrackingProps } from '@thriveglobal/thrive-web-tracking'
import {
    ContentFormatTypeValueType,
    ContentTypeValueType,
    PromptSelectedProperties,
    ResourceSelectedProperties
} from '@thriveglobal/thrive-web-tracking/dist/Avo'
import { ReactNullValue } from './nulls'

export function createDefaultTrackingProps<TProps>(
    props: TProps
): TProps & typeof defaultTrackingProps {
    return { ...defaultTrackingProps, ...props }
}

const placeholder: any = ReactNullValue

export const defaultPromptSelectedProps =
    createDefaultTrackingProps<PromptSelectedProperties>({
        contentFormatType: ReactNullValue,
        contentId: ReactNullValue,
        contentSource: ReactNullValue,
        contentSubtype: ReactNullValue,
        contentTitle: ReactNullValue,
        contentType: ReactNullValue,
        contentUrl: ReactNullValue,
        displayText: ReactNullValue,
        resetId: ReactNullValue,
        resetName: ReactNullValue,
        userId_: placeholder,
        featureType: placeholder,
        activityType: placeholder,
        isOnboarding: placeholder,
        tabName: ReactNullValue,
        tstPlatform: ReactNullValue,
        checkInDate: ReactNullValue,
        isAutomaticCheckin: placeholder,
        microstepBody: ReactNullValue,
        microstepId: ReactNullValue,
        microstepName: ReactNullValue,
        notificationCount: ReactNullValue
    })

export const defaultResourceSelectedProps =
    createDefaultTrackingProps<ResourceSelectedProperties>({
        contentSource: ReactNullValue,
        contentSubtype: ReactNullValue,
        featureType: placeholder,
        activityType: placeholder,
        isOnboarding: placeholder,
        journey: ReactNullValue,
        journeyId: ReactNullValue,
        journeyLevel: ReactNullValue,
        journeyDay: ReactNullValue,
        firstJourney: ReactNullValue,
        contentId: ReactNullValue as unknown as string,
        contentType: ReactNullValue as unknown as ContentTypeValueType,
        contentTitle: ReactNullValue as unknown as string,
        contentFormatType:
            ReactNullValue as unknown as ContentFormatTypeValueType,
        contentUrl: ReactNullValue as unknown as string,
        appUserId: ReactNullValue as unknown as string,
        tabName: ReactNullValue as unknown as undefined,
        displayText: ReactNullValue as unknown as string,
        notificationType: ReactNullValue as unknown as string
    })

export const defaultResourceServedProps =
    createDefaultTrackingProps<ResourceSelectedProperties>({
        contentSource: ReactNullValue,
        contentSubtype: ReactNullValue,
        featureType: placeholder,
        activityType: placeholder,
        isOnboarding: placeholder,
        journey: ReactNullValue,
        journeyId: ReactNullValue,
        journeyLevel: ReactNullValue,
        journeyDay: ReactNullValue,
        firstJourney: ReactNullValue,
        contentId: ReactNullValue as unknown as string,
        contentType: ReactNullValue as unknown as ContentTypeValueType,
        contentTitle: ReactNullValue as unknown as string,
        contentFormatType:
            ReactNullValue as unknown as ContentFormatTypeValueType,
        contentUrl: ReactNullValue as unknown as string,
        appUserId: ReactNullValue as unknown as string,
        tabName: ReactNullValue as unknown as undefined,
        displayText: ReactNullValue as unknown as string,
        notificationType: ReactNullValue as unknown as string
    })
