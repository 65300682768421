import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import isAfter from 'date-fns/isAfter'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
    DisplayTarget,
    NudgeType,
    PlatformCategory,
    UserNudgesSentResult,
    useGetPendingNudgesQuery
} from '../../graphql/generated/autogenerated'
import { defaultResourceServedProps } from '../../utils/defaultTrackingProps'
import { GQLNullValue, ReactNullValue } from '../../utils/nulls'
import { useBrowserNotification } from '../useBrowserNotification/useBrowserNotification'
import useLocalStorage from '../useLocalStorage/useLocalStorage'
import useZonedDateTime from '../useZonedDateTime/useZonedDateTime'

export const BrowserNotificationPermissionType =
    'BROWSER_NOTIFICATION_PERMISSIONS' as NudgeType
export const PseudoBrowserPermissionNotification = {
    read: false,
    expired: false,
    expiresAt: ReactNullValue,
    nudgeMetadata: {},
    nudgeType: BrowserNotificationPermissionType,
    producedAt: ReactNullValue,
    sendDate: ReactNullValue,
    sendTime: '',
    sentAt: ReactNullValue,
    targetTimestamp: ReactNullValue,
    thriveUserId: ReactNullValue,
    whereToShow: [DisplayTarget.Web]
}
export const ChallengeGoalReminder = 'IN_CHALLENGE_GOAL_REMINDER' as NudgeType

export interface Notification extends UserNudgesSentResult {
    read?: boolean
    expired?: boolean
}

const payload = {
    input: {
        isSent: GQLNullValue,
        platformCategories: [PlatformCategory.Web],
        within: GQLNullValue
    }
}
const todaysNotificationsKey = 'todaysNotifications'

const isNotificationEqual = (
    notification1: Notification,
    notification2: Notification
) => {
    return (
        notification1.nudgeType === notification2.nudgeType &&
        notification1.sendDate === notification2.sendDate &&
        notification1.sendTime === notification2.sendTime
    )
}

const areNotificationArraysEqual = (
    array1: Notification[],
    array2: Notification[]
) => {
    if (array1.length !== array2.length) return false
    return array1.every((notification, index) =>
        isNotificationEqual(notification, array2[index])
    )
}

export const useGetRecentNotifications = () => {
    const { data, refetch, loading } = useGetPendingNudgesQuery({
        variables: payload,
        fetchPolicy: 'cache-and-network'
    })

    const {
        isPermissionGranted,
        showNotification,
        generateBrowserNotification
    } = useBrowserNotification()
    const browserNotificationsEnabled = useIsFeatureEnabled(
        'BrowserNotifications',
        ReactNullValue,
        true
    )

    const location = useLocation()
    const { getZonedDate } = useZonedDateTime()
    const { get: getStoredNotifications, set: setStoredNotifications } =
        useLocalStorage<Notification[]>(todaysNotificationsKey)
    const [notifications, setNotifications] = useState<Notification[]>([])

    useEffect(() => {
        // Every 5 minutes, refetch the data.
        const interval = setInterval(() => {
            refetch(payload)
        }, 300000)

        return () => clearInterval(interval)
    }, [refetch])

    useEffect(() => {
        if (loading) return

        const handleExpiredNotifications = (
            notifications: Notification[] = []
        ) => {
            const currentZonedDate = getZonedDate()
            notifications.forEach((notification) => {
                if (!notification.expired) {
                    notification.expired =
                        notification.expiresAt &&
                        isAfter(
                            currentZonedDate,
                            getZonedDate(new Date(notification.expiresAt))
                        )
                }
            })
        }

        const storedNotifications = getStoredNotifications() ?? []
        const pendingNudges = data?.centralizedNotifications
            ?.pendingNudges as any
        const results = (pendingNudges?.result as Notification[]) ?? []

        const pseudoNotificationExists = results.some(
            (notification: Notification) =>
                notification.nudgeType ===
                PseudoBrowserPermissionNotification.nudgeType
        )

        if (
            browserNotificationsEnabled &&
            !isPermissionGranted &&
            !pseudoNotificationExists
        ) {
            results.push(PseudoBrowserPermissionNotification)
        }

        // Check if new data is different from stored notifications
        const newDataExistsAndIsDifferent =
            data &&
            !areNotificationArraysEqual(results || [], storedNotifications)

        // If there are stored notifications and the new data is not different, use stored notifications
        if (storedNotifications.length > 0 && !newDataExistsAndIsDifferent) {
            handleExpiredNotifications(storedNotifications)
            setNotifications((prevNotifications) => {
                if (
                    !areNotificationArraysEqual(
                        storedNotifications,
                        prevNotifications
                    )
                ) {
                    setStoredNotifications(storedNotifications)
                    return storedNotifications
                }
                return prevNotifications
            })
            return
        }

        // If new data exists and is different, use the new data
        if (data) {
            const todaysNotifications = results
            handleExpiredNotifications(todaysNotifications)
            setNotifications((prevNotifications) => {
                if (
                    !areNotificationArraysEqual(
                        todaysNotifications,
                        prevNotifications
                    )
                ) {
                    const latestNotification = todaysNotifications?.[0]
                    if (isPermissionGranted && browserNotificationsEnabled) {
                        if (latestNotification) {
                            const browserNotification: any =
                                generateBrowserNotification(latestNotification)
                            showNotification(
                                browserNotification.title,
                                browserNotification.url,
                                browserNotification.options
                            )
                        }
                    }
                    if (
                        latestNotification?.nudgeType ===
                        NudgeType.SmartNudgeMsTeamsMvp
                    ) {
                        Avo.resourceServed({
                            ...defaultResourceServedProps,
                            featureType: 'notifications',
                            activityType: 'reset_notification_served'
                        })
                    }
                    setStoredNotifications(todaysNotifications)
                    return todaysNotifications
                }
                return prevNotifications
            })
        }
    }, [
        data,
        loading,
        getZonedDate,
        showNotification,
        isPermissionGranted,
        getStoredNotifications,
        setStoredNotifications,
        browserNotificationsEnabled,
        generateBrowserNotification
    ])

    const { hasUnreadNotifications, unreadNotificationCount } = useMemo(() => {
        const unreadNotifications = notifications?.filter(
            (notification) => !notification.read && !notification.expired
        )
        return {
            hasUnreadNotifications: unreadNotifications?.length > 0,
            unreadNotificationCount: unreadNotifications?.length
        }
    }, [notifications])

    const handleTitleUpdate = useCallback(() => {
        const timeoutId = setTimeout(() => {
            const regex = /\(\d+\)\s/
            let updatedTitle = document.title

            if (unreadNotificationCount > 0) {
                if (regex.test(updatedTitle)) {
                    updatedTitle = updatedTitle.replace(
                        regex,
                        `(${unreadNotificationCount}) `
                    )
                } else {
                    updatedTitle = `(${unreadNotificationCount}) ${updatedTitle}`
                }
            } else {
                // Remove the notification count from the title if unreadNotificationCount is 0
                updatedTitle = updatedTitle.replace(regex, '')
            }

            document.title = updatedTitle
        }, 1000)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [unreadNotificationCount])

    const setNotificationRead = useCallback(() => {
        const notifs = notifications.map((notification) => ({
            ...notification,
            read: true
        }))
        setNotifications(notifs)
        setStoredNotifications(notifs)
        handleTitleUpdate()
    }, [notifications, handleTitleUpdate, setStoredNotifications])

    const setNotificationExpired = useCallback(
        (notification: Notification) => {
            const notifs = notifications.map((n) =>
                n === notification ? { ...n, expired: true } : n
            )
            setNotifications(notifs)
            setStoredNotifications(notifs)
        },
        [notifications, setStoredNotifications]
    )

    useEffect(() => {
        handleTitleUpdate()
    }, [location, handleTitleUpdate])

    return {
        notifications,
        hasUnreadNotifications,
        setNotificationRead,
        setNotificationExpired
    }
}

export default useGetRecentNotifications
